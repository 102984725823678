import React from 'react';
import ReactDOM from 'react-dom';


import './styling/About.css';
import logo from "./styling/logo1.png"
import logowname from "./styling/name.png"
import long from "./styling/long.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"

function About() {
    return (
      <>
  
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
  </style> 
  
    <header className='nav'>
      
      <div className="main-container">
  
      <nav className='nav-bar'>
          
          <a href='/'>  
          <div className="logo">
          <img src={logo} className='logo'></img>
          </div>
          </a>
  
            <div className='top-container' id='menu'>
  
          <div className='top-links'>
            <a href='/'>Home</a>
          </div>
  
          <div className='top-links'>
          <a href='/about'>Origin</a>
          </div>
  
          <div className='top-links'>
          <a href='/projects'>Projects</a>
          </div>
  
          <div className='top-links'>
          <a href='#'>Lore</a>
          </div>
  
            </div>
  
          </nav>

          <div className='second-container'>

      <div className='intro'>
      <div className='topic'>ORIGIN</div>
      <div className='bob'>
      
      <img src={logowname} className='name'></img>
      </div>
      
      <p>
        In the epicentre of 2021 a team of young minds decided that they wanted to bring about change by pioneering in a new 
        technological revolution, the Advent of Blockchain Technology along with the Metaverse.
        We saw ways in which we could integrate this new technology to
        the benefit of our people, increasing Security, Access, Usage and Efficiency.
      </p>

      <p id='wow'>
        And with that ADENA LABS was born.
      </p>

      </div>

      <div className='third-container'>
        <p id='topic'>Founding Members</p>
        <p id='content-2'>
        <ul>
          <li>Sydney Gooden - CEO/Visionary/Founder</li>
          <li>Mark-Anthony Baker - Blockchain Developer</li>
          <li>Makhaya A Mcdonald - 3D Artist</li>
          <li>Tahjaye Gordon - 2D Artist</li>
        </ul>
        </p>
      </div>


      </div>
          </div>

          </header>

          <footer className='footer'>

<div className='contact'>
<p>Contact Info:</p>

<div>
<a href='https://www.instagram.com/adenalabs.io/'>
<FontAwesomeIcon className='ico-ii' icon={faInstagram} />
</a>
<a href='https://twitter.com/ADENALABS_IO'>
<FontAwesomeIcon className='icon-ii' icon={faTwitter} />
</a>
<a href='https://www.linkedin.com/company/adena-labs-limited'>
<FontAwesomeIcon className='ico-ii' icon={faLinkedin} />
</a>
</div>

<p>Email - developmentadena@gmail.com</p>

<div>
    <p>ADENA LABS LIMITED &trade; &copy; 2022</p>
  </div>
</div>

<div>
<img src={long} className='nimage'/>
</div>

  </footer>

        </>
        );
}

export default About;
