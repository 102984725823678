import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';

import './styling/Adna.css';
import logo from "./styling/logo1.png"
import name from "./styling/name.png"
import long from "./styling/long.png"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"


function Home() {

    const navigate = useNavigate()
    const [Email, setEmail] = useState('')
    const [Message, setMessage] = useState('')

async function message (event) {

    event.preventDefault()
    await fetch('https://adenaweb.herokuapp.com/api/msg', {

        method:'POST',
  
    headers: {
    'Content-Type': 'application/json',
  },

    body: JSON.stringify({
      Message, 
      Email, 
    }),
    })

    navigate('/')

    window.location.reload(false);

}


function clear () {
    window.location.reload(false);
}

  return (
    <>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
</style> 


  <header className='nav'>
    
    <div className="main-container">

        <nav className='nav-bar'>
          
        <a href='/'>  
        <div className="logo">
				<img src={logo} className='logo'></img>
			  </div>
        </a>

          <div className='top-container' id='menu'>

        <div className='top-links'>
          <a href='/'>Home</a>
        </div>

        <div className='top-links'>
        <a href='/about'>Origin</a>
        </div>

        <div className='top-links'>
        <a href='/projects'>Projects</a>
        </div>

        <div className='top-links'>
        <a href='#'>Lore</a>
        </div>

          </div>

        </nav>

<div className='second-container'>

      

      <div className='intro'>
      <div className='bob'><img src={name} className='name'></img></div>
      
        <p id='s-para'>
        <div className='who'>
          Who are we?
        </div>
          The first Metaverse specialized software lab in Jamaica.
        </p>
        
    <div id='t-para'>
      <p>
        What we do :
        <ul>
          <li>Integrate businesses/persons with Blockchain Technology.</li>
          <li>Provide gateways to access the Metaverse.</li>
          <li>Holistic blockchain development.</li>
        </ul>
      </p>
    </div>

      </div>

      <div className='third-container'>
        <p id='topic'>Services Provided :</p>
          <p id='content-1'>
            <ul>
              <li>Utility NFT Creation (Alpha)</li>
              <li>3D Modeling</li>
              <li>AR Systems (Alpha)</li>
              <li>2D Graphic Creation</li>
              <li>Blockchain Consultation</li>
              <li>Smart Contract Creation (Alpha)</li>
              <li>NFC Integration (Alpha & Beta)</li>
            </ul>
          </p>

        <div id='topic-u'>
            Message Us //
        </div>

          <div className='message'>

          <form onSubmit={message} className='form'>
        <div>
        <input type='email' onChange={(e)=> setEmail(e.target.value)} value={Email} placeholder="Your Email" className='sub-input-1'/>
        </div>
        <div>
        <textarea type='text' value={Message} onChange={(e)=> setMessage(e.target.value)} placeholder="Message" className='sub-input-2'/>
        </div>
        <div>
        <input type='submit' className='sub-btn' placeholder='Submit'/>
        </div>
          </form>
    </div>

      </div>

</div>



    </div>

  </header>

  <footer className='footer'>

<div className='contact'>
<p>Contact Info:</p>

<div> 
<i className='instgram-icon'>
<a href='https://www.instagram.com/adenalabs.io/'>
<FontAwesomeIcon className='icon-ii' icon={faInstagram} />
</a>
</i>
<a href='https://twitter.com/ADENALABS_IO'>
<FontAwesomeIcon className='icon-iit' icon={faTwitter} />
</a>
<a href='https://www.linkedin.com/company/adena-labs-limited'>
<FontAwesomeIcon className='icon-iit' icon={faLinkedin} />
</a>
</div>

<p>Email - developmentadena@gmail.com</p>

<div>
    <p>ADENA LABS LIMITED &trade; &copy; 2022</p>
  </div>
</div>

<div className='fimage'>
<img src={long} className='nimage'/>
</div>

    

  </footer>
    
    </>
  );
}


export default Home;
