import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import About from "./pages/About"
import Home from "./pages/Home"
import Projects from "./pages/Projects"

function App() {
  return (
    <>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
</style> 

        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/projects" element={<Projects/>}/>
        </Routes>
        </BrowserRouter>    

    </>
  );
}

export default App;
