import React from 'react';
import ReactDOM from 'react-dom';


import './styling/About.css';
import logo from "./styling/logo1.png"
import logowname from "./styling/name.png"
import long from "./styling/long.png"
import lifec from "./styling/lifec.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"

function Projects() {
    return (
      <>
  
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
  </style> 
  
    <header className='nav'>
      
      <div className="main-container">
  
      <nav className='nav-bar'>
          
          <a href='/'>  
          <div className="logo">
          <img src={logo} className='logo'></img>
          </div>
          </a>
  
            <div className='top-container' id='menu'>
  
          <div className='top-links'>
            <a href='/'>Home</a>
          </div>
  
          <div className='top-links'>
          <a href='/about'>Origin</a>
          </div>
  
          <div className='top-links'>
          <a href='/projects'>Projects</a>
          </div>
  
          <div className='top-links'>
          <a href='#'>Lore</a>
          </div>
  
            </div>
  
          </nav>

          <div className='second-container'>

      <div className='intro'>
      <div className='topic'>PROJECTS</div>
      <div className='mayn'>
      <img src={logowname} className='name'></img>
      <img src={lifec} className='name'></img>
      </div>

      
      
      <p>
      LifeCall Ambulance Service, is a company that provides medical transport for persons
      in jamaica. They pointed out to us that majority of their business comes from tourists.
      So they wanted to provide something appealing and convenient to that audience. 
      </p>

      <p id='wow'>
    
      </p>

      </div>

      <div className='third-container'>
        <p id='topic'>What we did :</p>
        <ul>
          <li>Integrated a Cryptocurrency Payment System</li>
          Consisting Of:
          <li>A Domain address</li>
          <li>Acceptance of 3 Cryptocurrencies</li>
          <li>Company Crypto Acccount</li>
        </ul>
      </div>


      </div>
          </div>

          </header>

          <footer className='footer'>

<div className='contact'>
<p>Contact Info:</p>

<div>
<a href='https://www.instagram.com/adenalabs.io/'>
<FontAwesomeIcon className='ico-ii' icon={faInstagram} />
</a>
<a href='https://twitter.com/ADENALABS_IO'>
<FontAwesomeIcon className='icon-ii' icon={faTwitter} />
</a>
<a href='https://www.linkedin.com/company/adena-labs-limited'>
<FontAwesomeIcon className='ico-ii' icon={faLinkedin} />
</a>
</div>

<p>Email - developmentadena@gmail.com</p>

<div>
    <p>ADENA LABS LIMITED &trade; &copy; 2022</p>
  </div>
</div>

<div>
<img src={long} className='nimage'/>
</div>

  </footer>

        </>
        );
}

export default Projects;